import React, { Fragment, useEffect, useRef, useState } from 'react';
import { formatRupiah, urlApi } from '../features/authSlice';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from '../lottie/not-found.json';

const Product = ({ searchTerm, selectedLocations = [], produk, selectedCategories = [] }) => {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [bookmarkStates, setBookmarkStates] = useState({});
    const [heartStates, setHeartStates] = useState({});
    const [tenants, setTenants] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;
    const navigate = useNavigate();

    const handleBookmarkClick = (id) => {
        setBookmarkStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };

    const handleHeartClick = (id) => {
        setHeartStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };

    useEffect(() => {
        getTenants();
        getCategories();
        getData();
    }, []);

    const getCategories = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${urlApi}/kategoriproduk`);
            const categoriesData = response.data.kategoriProduk || [];
            setCategories(categoriesData);
        } catch (error) {
            setLoading(false);
            Swal.fire('Error', 'There was an error fetching data!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${urlApi}/produks`);
            const products = response.data.produkList || [];
            setData(products);
            setTotal(products.length);
        } catch (error) {
            setLoading(false);
            Swal.fire('Error', 'There was an error fetching data!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const getTenants = async () => {
        try {
            const response = await axios.get(`${urlApi}/tenant`);
            setTenants(response.data.tenantList);
        } catch (error) {
            Swal.fire('Error', 'There was an error fetching tenant data!', 'error');
        }
    };

    const combinedProducts = data.map(product => {
        const tenant = (Array.isArray(tenants) ? tenants : []).find(t => t.id === product.tenantId);
        const category = categories.find(c => c.id === product.kategoriId);
        return {
            ...product,
            tenantName: tenant ? tenant.nama : 'Unknown',
            categoryName: category ? category.kategori : 'Unknown',
            tenantLocation: tenant ? tenant.alamat : '',
        };
    });

    const handleCategoryClick = (category) => {
        setSelectedCategory(category.kategori);
    };

   const filteredProducts = combinedProducts.filter(product => {
    const productName = product.nama ? product.nama.toLowerCase() : '';
    const tenantLocation = product.tenantLocation ? product.tenantLocation.toLowerCase() : '';
    const searchTermLower = searchTerm ? searchTerm.toLowerCase() : '';

    const category = categories.find(k => k.id === product.kategoriId);
    const categoryName = category && category.kategori ? category.kategori.toLowerCase() : '';

    const matchName = productName.includes(searchTermLower);
    const matchCategory = selectedCategory
        ? categoryName === selectedCategory.toLowerCase()
        : true;
    const matchLocation = selectedLocations.length
        ? selectedLocations.some(location => location.value.toLowerCase() === tenantLocation)
        : true;

    return matchName && matchCategory && matchLocation;
});


    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const notFoundRef = useRef(null);

    const handleProductClick = (product) => {
        navigate(`/product/${product.id}`, { state: { product } });
    };

    return (
        <Fragment>
            <section className="job-section section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12 mb-lg-4">
                            <h3>Results of {filteredProducts.length} of {total} product</h3>
                        </div>
                        <div className="col-lg-4 col-12 d-flex align-items-center ms-auto mb-5 mb-lg-4">
                            <p className="mb-0 ms-lg-auto">Sort by:</p>
                            <div className="dropdown dropdown-sorting ms-3 me-4">
                                <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownSortingButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {selectedCategory ? selectedCategory : 'Kategori'}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownSortingButton">
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            onClick={() => handleCategoryClick(null)}
                                        >
                                            Semua Kategori
                                        </Link>
                                    </li>
                                    {categories.map((category, index) => (
                                        <li key={index}>
                                            <Link
                                                className="dropdown-item"
                                                onClick={() => handleCategoryClick(category)}
                                            >
                                                {category.kategori}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {currentProducts.length > 0 ? 
                        (currentProducts.map((produk) => (
                            <div className="col-lg-4 col-md-6 col-12" key={produk.id}>
                                <div className="job-thumb job-thumb-box">
                                    <div className="job-image-box-wrap">
                                        <img 
                                            src={produk.gambarUrl} 
                                            className="job-image img-fluid" 
                                            style={{
                                                width: '28rem', 
                                                height: '210px', 
                                                objectFit: 'cover', 
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                                            }}
                                        />
                                        <div className="job-image-box-wrap-info d-flex align-items-center">
                                            <p className="mb-0 badge">
                                                {produk.categoryName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="job-body">
                                        <h4 className="job-title">
                                            <span className="job-title-link" style={{ cursor: 'pointer' }}>{produk.nama}</span>
                                        </h4>
                                        <div className="d-flex align-items-center">
                                            <div className="job-image-wrap d-flex align-items-center bg-white shadow-lg mt-2 mb-4">
                                                <img src={produk.gambarUrl} className="job-image me-3 img-fluid" alt={produk.nama} />
                                                {produk.nama.length > 7 ? `${produk.nama.substring(0, 7)}...` : produk.nama}
                                                </div>
                                            <span
                                                className={`bi ${bookmarkStates[produk.id] ? 'bi-bookmark-fill  ms-auto me-2' : 'bi-bookmark  ms-auto me-2'} ${heartStates[produk.id] ? 'liked-bookmark' : ''}`}
                                                onClick={() => handleBookmarkClick(produk.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                            </span>
                                            <span
                                                className={`bi ${heartStates[produk.id] ? 'bi-heart-fill' : 'bi-heart'} ${heartStates[produk.id] ? 'liked-heart' : ''}`}
                                                onClick={() => handleHeartClick(produk.id)}
                                                style={{ cursor: 'pointer', color: heartStates[produk.id] ? 'text-danger' : 'inherit' }}
                                            >
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="job-location">
                                                <i className="custom-icon bi-geo-alt me-1" />
                                                {produk.tenantName}
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center border-top pt-3">
                                            <p className="job-price mb-0">
                                                <i className="custom-icon bi-cash me-1" />
                                                {formatRupiah(produk.harga)}
                                            </p>
                                            <button className="custom-btn btn ms-auto" onClick={() => handleProductClick(produk)}>Detail</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))
                        : (
                            <div ref={notFoundRef} className="text-center d-flex justify-content-center align-items-center">
                                <Lottie animationData={animationData} loop={true} style={{ height: 200, width: 200 }} />
                            </div>
                        )}
                    </div>
                  <div className="col-lg-12 col-12">
                      <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center mt-5">
                              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                  <div className="page-link" aria-label="Previous" onClick={() => paginate(currentPage - 1)} tabIndex={currentPage === 1 ? -1 : 0} style={{cursor: 'pointer'}}>
                                      <span aria-hidden="true">Prev</span>
                                  </div>
                              </li>
                              {[...Array(totalPages).keys()].map(number => (
                                  <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                      <div className="page-link" onClick={() => paginate(number + 1)}>
                                          {number + 1}
                                      </div>
                                  </li>
                              ))}
                              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                  <div className="page-link" aria-label="Next" onClick={() => paginate(currentPage + 1)} tabIndex={currentPage === totalPages ? -1 : 0} style={{cursor: 'pointer'}}>
                                      <span aria-hidden="true">Next</span>
                                  </div>
                              </li>
                          </ul>
                      </nav>
                  </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Product;
