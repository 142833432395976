import React, { Fragment } from 'react'

const Footer = () => {
  return (
    <Fragment>
         {/* Footer Start */}
  <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Our Office</h4>
          <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />Jln Tole Iskandar perumahan Bella casa B1/16 Depok Jawabarat.</p>
          <p className="mb-2"><i className="fa fa-phone-alt me-3" />+62 8129-5888-374</p>
          <p className="mb-2"><i className="fa fa-envelope me-3" />bisearch@gmail.com</p>
          <div className="d-flex pt-2">
            <a href='https://www.instagram.com/digital.platform.partner/' className="btn btn-square btn-outline-light rounded-circle me-2" ><i className="bi bi-instagram" /></a>
            <a href='https://wa.me/+6281295888374' className="btn btn-square btn-outline-light rounded-circle me-2" ><i className="bi bi-whatsapp" /></a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Services</h4>
          <a className="btn btn-link" >Attendance</a>
          <a className="btn btn-link" >Financial Monitoring</a>
          <a className="btn btn-link" >Inventory Management</a>
          <a className="btn btn-link" >Enhanced Securty</a>
          <a className="btn btn-link" >CRM</a>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Quick Links</h4>
          <a className="btn btn-link" >About Us</a>
          <a className="btn btn-link" >Contact Us</a>
          <a className="btn btn-link" >Our Services</a>
          <a className="btn btn-link" >Terms &amp; Condition</a>
          <a className="btn btn-link" >Support</a>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-white mb-4">Newsletter</h4>
          <p>Bizup adalah platform teknologi inovatif yang dirancang khusus untuk membantu UMKM di Indonesia berkembang pesat.</p>
         
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Copyright Start */}
  <div className="container-fluid copyright py-4">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
          © <a className="border-bottom" href='https://saefull.my.id/' >Solid team 2024</a>, All Right Reserved.
        </div>
      </div>
    </div>
  </div>
  {/* Copyright End */}
    </Fragment>
  )
}

export default Footer
