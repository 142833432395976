import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Header from './components/Header';
import Home from './pages/Home';
import GetComunitas from './pages/GetComunitas';
import GetProduct from './pages/GetProduct';

const App = () => {
  
  return (
    <div>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/community/:nama" element={<GetComunitas/>} />
      <Route path="/product/:id" element={<GetProduct/>} />
    </Routes>
  </BrowserRouter>
    </div>
  )
}

export default App
