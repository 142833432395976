import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatRupiah, urlApi } from '../../features/authSlice';

const Detail = () => {
  const [nama, setNama] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [harga, setHarga] = useState('');
  const [tenant, setTenant] = useState('');
  const [kategori, setKategori] = useState('');
  const [gambarUrl, setGambarUrl] = useState(null);
  const [tenantData, setTenantData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch produk data
        const produkResponse = await axios.get(`${urlApi}/produk/${id}`);
        const produkData = produkResponse.data;

        // Set produk data
        setHarga(produkData.harga);
        setTenant(produkData.tenantNama);
        setKategori(produkData.kategoriNama);
        setGambarUrl(produkData.gambarUrl);
        setNama(produkData.nama);
        setDeskripsi(produkData.deskripsi);

        // Fetch tenant data based on tenant ID
        const tenantResponse = await axios.get(`${urlApi}/tenant/${produkData.tenantId}`);
        setTenantData(tenantResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <section className="job-section section-padding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <h2 className="job-title mb-0">{nama}</h2>
              <div className="job-thumb job-thumb-detail">
                <div className="d-flex flex-wrap align-items-center border-bottom pt-lg-3 pt-2 pb-3 mb-4">
                  <p className="job-location mb-0">
                    <i className="custom-icon bi-geo-alt me-1" />
                    {tenantData.alamat || 'Alamat tidak tersedia'}
                  </p>
                  <p className="job-price mb-0">
                    <i className="custom-icon bi-cash me-1" />
                    {formatRupiah (harga)}
                  </p>
                  <div className="d-flex">
                    <p className="mb-0 badge badge-level" style={{cursor: 'pointer'}}>
                      {kategori}
                    </p>
                  </div>
                </div>
                <h4 className="mt-4 mb-2">Product Description</h4>
                <p>{deskripsi}</p>
                <div className="p-4">
                  <h4>Contact Tenant</h4>
                  <ul>

                  </ul>
                  <li><strong>Tenant Name:</strong> {tenantData.nama || 'Contact tidak tersedia'}</li>
                  <li><strong>Contact:</strong> {tenantData.contact || 'Contact tidak tersedia'}</li>
                  <li><strong>Address:</strong> {tenantData.alamat || 'Alamat tidak tersedia'}</li>
                  {/* <li><strong>Kategori:</strong> {tenantData.kategori || 'Kategori tidak tersedia'}</li> */}
                </div>
                <div className="d-flex justify-content-center flex-wrap mt-5 border-top pt-4">
                  {/* <button className="custom-btn btn mt-2 px-5">Buy</button> */}
                  <div className="job-detail-share d-flex align-items-center">
                    <p className="mb-0 me-lg-4 me-3">Chat:</p>
                    <a
                        href={
                          tenantData?.contact
                            ? `https://wa.me/${tenantData.contact.replace(/^08/, '+628')}`
                            : '#'
                        }
                        className="bi bi-whatsapp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >

                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-5 mt-lg-0">
                {/* {gambarUrl ? (
                  <img className="img-fluid rounded bg-white shadow-lg" data-wow-delay="0.1s" src={gambarUrl} alt={nama} />
                ) : null} */}
                <div className="job-image-box-wrap">
                  {gambarUrl ? (
                    <img 
                    src={gambarUrl} 
                    className="job-image img-fluid" 
                    alt={nama}
                    style={{
                        width: '28rem', 
                        height: '26rem', 
                        objectFit: 'cover', 
                        // borderRadius: '10px', // Add rounded corners
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                    }}
                    />
                  ): null}
                    <div className="job-image-box-wrap-info d-flex align-items-center">
                    <p className="mb-0 badge">
                    {kategori}
                    </p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Detail;
