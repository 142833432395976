import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { urlApi } from '../../features/authSlice';
import axios from 'axios';

const Navbar = () => {
  const [name, setName] = useState('');
  const [lokasi, setLokasi] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const { nama } = useParams(); // Ambil nama komunitas dari URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urlApi}/komunitas/${nama}`);
        setName(response.data.nama);
        setLokasi(response.data.lokasi);
        setLogoUrl(response.data.logoUrl);
      } catch (error) {
        console.error('Error fetching komunitas:', error);
      }
    };

    fetchData();
  }, [nama]);

  useEffect(() => {
    // Hide spinner after 1 second
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {loading && (
        <div id="spinner" className="show w-100 vh-100 bg-white position-fixed translate-middle top-50 start-50  d-flex align-items-center justify-content-center">
          <div className="spinner-grow text-primary" role="status"></div>
        </div>
      )}

      {!loading && (
        <Fragment>
          {/* Navbar Start */}
          <nav className={`navbar navbar-expand-lg navbar-light sticky-top p-0 ${scrolled ? 'bg-light shadow-sm' : 'bg-white'}`}>
            <div className="navbar-brand d-flex align-items-center px-4 px-lg-5">
            {logoUrl ? <img src={logoUrl} alt="Community Logo" className="community-logo" /> : null}
              <h1 className="community-name">{name}</h1>
            </div>
           
          </nav>
          {/* Navbar End */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Navbar;
