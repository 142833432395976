import React, { Fragment, useEffect, useState } from 'react';
import carousel3 from '../img/carousel-3.png';
import carousel4 from '../img/carousel-1.png';
import { Link } from 'react-router-dom';
import { urlApi } from '../features/authSlice';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';

const Header = ({ setSearchTerm, setSelectedLocations }) => {
  const [data, setData] = useState([]);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    
    getData();
    getLocations();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/kategoriproduk`);
      const categories = response.data.kategoriProduk || [];
      setData(categories.slice(0, 4)); // Limit to 4 categories
    } catch (error) {
      setLoading(false);
      Swal.fire('Error', 'There was an error fetching data!', 'error');
    } finally {
      setLoading(false);
    }
  };
  const getLocations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/tenant`);
      const locationData = response.data.tenantList.map((item) => ({
        value: item.alamat,
        label: item.alamat,
      }));
      setLocations(locationData);
    } catch (error) {
      setLoading(false);
      Swal.fire('Error', 'There was an error fetching data!', 'error');
    } finally {
      setLoading(false);
    }
  };


  const handleSearchChange = (e) => {
    setText(e.target.value);
    setSearchTerm(e.target.value); // Update the search term in the parent component
  };

  return (
    <Fragment>
      {/* Carousel Start */}
      <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s" id="home">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={carousel3} alt="Image" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <h1 className="display-1 text-white mb-5 animated slideInDown">Search for Businesses around you</h1>
                      <button className="btn btn-primary py-sm-3 px-sm-4" onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth' })}>
                        Explore More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={carousel4} alt="Image" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <h1 className="display-1 text-white mb-5 animated slideInDown">Search for Businesses around you</h1>
                      <button className="btn btn-primary py-sm-3 px-sm-4" onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth' })}>
                        Explore More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
      {/* Top Feature Start */}
      <div className="container-fluid top-feature py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-12">
              <form className="custom-form hero-form" action="#" method="get" role="form">
                <h1 className="text-white mb-3 text-center">Search</h1>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="bi bi-search" />
                      </span>
                      <input type="text" className="form-control" placeholder="Search ..." value={text} onChange={handleSearchChange} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="custom-input-group">
                      <i className="bi-geo-alt custom-icon custom-input-group-icon" />
                      <Select
                        isMulti
                        options={locations}
                        className="custom-select-container"
                        classNamePrefix="custom-select"
                        placeholder="Select locations"
                        onChange={setSelectedLocations} // Update the selected locations in the parent component
                        styles={{
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            fontFamily: 'Poppins',
                            fontSize: 15,
                            color: '#adb5bd',
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: 13,
                            fontFamily: 'Poppins',
                            color: '#000000',
                          }),
                          clearIndicator: (baseStyles) => ({
                            ...baseStyles,
                            color: 'red',
                          }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: 0,
                          }),
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-12 col-12 text-center">
                    <button type="submit" className="btn btn-primary py-2 px-4">
                      Search
                    </button>
                  </div> */}
                  {/* <div className="col-12">
                    <div className="d-flex flex-wrap align-items-center mt-4 mt-lg-0">
                      <span className="text-white mb-lg-0 mb-md-0 me-2">Popular keywords:</span>
                      <div>
                        {data.map((kategori, index) => (
                          <Link className="badge" key={index}>
                            {kategori.kategori}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Top Feature End */}
    </Fragment>
  );
};

export default Header;
