import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import about from '../img/about.jpg';
import Swal from 'sweetalert2';
import { urlApi } from '../features/authSlice';

const About = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [communities, setCommunities] = useState([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/komunitas`, {
      });
      setCommunities(response.data);
    } catch (error) {
        // Handle other errors
        Swal.fire('Error', 'There was an error fetching data!', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCommunityClick = (community) => {
    navigate(`/community/${community.nama}`, { state: { community } });
  };

  return (
    <div>
      {/* Community Logos Start */}
      <div className="container-fluid bg-primary py-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          <h1 className="display-3 text-white mb-4">Our Communities</h1>
          <div className="d-flex justify-content-center flex-wrap">
            {communities.map((community) => (
              <div key={community.id} className="mx-3" onClick={() => handleCommunityClick(community)}>
                <img
                  className="img-fluid rounded-circle"
                  src={community.logoUrl}
                  alt={community.nama}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer', background: '#fff' }}
                />
                <p className="text-white text-bold mt-2">{community.nama}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Community Logos End */}
      {/* About Start */}
      <div id="about" className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end">
            <div className="col-lg-4 col-md-5 wow fadeInUp" data-wow-delay="0.1s">
              <img className="img-fluid rounded" data-wow-delay="0.1s" src={about} alt="About" />
            </div>
            <div className="col-lg-7 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="display-1 text-primary mb-0">5</h1>
              <p className="text-primary mb-4">Year of Experience</p>
              <h1 className="display-5 mb-4">We make this application a bridge</h1>
              <p className="mb-4">
                Bizup adalah platform teknologi inovatif yang dirancang khusus untuk membantu UMKM di Indonesia
                berkembang pesat. Kami memahami tantangan unik yang dihadapi UMKM, dan kami berkomitmen untuk
                menyediakan solusi teknologi yang mudah digunakan, terjangkau, dan efektif untuk membantu Anda
                mengelola bisnis dengan lebih mudah dan efisien.
              </p>             
            </div>
            
          </div>
        </div>
      </div>
      {/* About End */}

      
    </div>
  );
};

export default About;
