import React from 'react';
import service1 from '../img/service-1.jpg';
import service2 from '../img/service-2.jpg';
import service3 from '../img/service-3.jpg';
import service4 from '../img/service-4.jpg';
import service5 from '../img/service-5.jpg';
import service6 from '../img/service-6.jpg';
import icon2 from '../img/icon/icon-2.png';
import icon3 from '../img/icon/icon-3.png';
import icon4 from '../img/icon/icon-4.png';
import icon5 from '../img/icon/icon-5.png';
import icon6 from '../img/icon/icon-6.png';
import icon8 from '../img/icon/icon-8.png';

const Services = () => {
  return (
    <div>
      {/* Features Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="fs-5 fw-bold text-primary">Why Choosing Us!</p>
              <h1 className="display-5 mb-4">Few Reasons Why People Choosing Us!</h1>
              <p className="mb-4">Ribuan UMKM di seluruh Indonesia telah merasakan manfaat Bizup. Bergabunglah dengan mereka dan rasakan sendiri bagaimana Bizup dapat membantu Anda membawa bisnis Anda ke level selanjutnya.</p>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                        <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                          <i className="fa fa-check fa-3x text-primary" />
                        </div>
                        <h4 className="mb-0">100% Satisfaction</h4>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                        <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                          <i className="fa fa-users fa-3x text-primary" />
                        </div>
                        <h4 className="mb-0">Dedicated Team</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                  <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                    <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                      <i className="fa fa-tools fa-3x text-primary" />
                    </div>
                    <h4 className="mb-0">Modern Equipment</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}
      {/* Service Start */}
      <div className="container-xxl py-5" id='services'>
        <div className="container">
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
            <p className="fs-5 fw-bold text-primary">Our Services</p>
            <h1 className="display-5 mb-5">Services That We Offer For You</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service1} alt="service1" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon3} alt="Icon" />
                  </div>
                  <h4 className="mb-3">Attendance</h4>
                  <p className="mb-4">Kelola kehadiran karyawan Anda dengan mudah dan akurat menggunakan aplikasi absensi Bizup. Anda dapat melacak jam kerja dan cuti.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service2} alt="service2" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon6} alt="Icon" />
                  </div>
                  <h4 className="mb-3">Financial Monitoring</h4>
                  <p className="mb-4">Pantau keuangan bisnis Anda secara real-time dengan aplikasi monitoring keuangan Bizup. Anda dapat melihat laporan laba rugi dengan mudah.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service3} alt="service3" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon5} alt="Icon" />
                  </div>
                  <h4 className="mb-3">Inventori Management</h4>
                  <p className="mb-4">Kelola inventaris produk Anda dengan mudah dan efisien dengan aplikasi manajemen inventaris Bizup.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service4} alt="service4" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon4} alt="Icon" />
                  </div>
                  <h4 className="mb-3">CRM</h4>
                  <p className="mb-4">Bangun dan kelola hubungan pelanggan Anda dengan mudah menggunakan aplikasi CRM Bizup. Anda dapat menyimpan data pelanggan.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service5} alt="service5" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon8} alt="Icon" />
                  </div>
                  <h4 className="mb-3">Enhanced Security</h4>
                  <p className="mb-4">Bizup mengutamakan keamanan data bisnis Anda. Kami menerapkan langkah-langkah keamanan yang kuat untuk melindungi informasi Anda.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src={service6} alt="service6" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img className="img-fluid" src={icon2} alt="Icon" />
                  </div>
                  <h4 className="mb-3">Seamless Integration</h4>
                  <p className="mb-4">Bizup terintegrasi secara mulus dengan sistem bisnis Anda yang ada, menghilangkan silo data dan menyederhanakan operasi.</p>
                  <a className="btn btn-sm" href="#"><i className="fa fa-plus text-primary me-2" />Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
    </div>
  );
};

export default Services;