import React from 'react';
import service_1 from '../img/service-1.jpg';
import service_2 from '../img/service-2.jpg';
import service_3 from '../img/service-3.jpg';
import service_4 from '../img/service-4.jpg';
import service_5 from '../img/service-5.jpg';
import service_6 from '../img/service-6.jpg';

const Project = () => {
  // Function to validate if the URL is a string
  const validateHref = (url) => (typeof url === 'string' ? url : '');

  return (
    <>
      {/* Projects Start */}
      <div className="container-xxl py-5" id='projects'>
        <div className="container">
          <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
            <p className="fs-5 fw-bold text-primary">Our Projects</p>
            <h1 className="display-5 mb-5">Some Of Our Wonderful Projects</h1>
          </div>
          <div className="row g-4 portfolio-container">
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_1} alt="Service 1" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">Attendance</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_1)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_2} alt="Service 2" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">Financial Monitoring</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_2)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_3} alt="Service 3" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">Inventory Management</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_3)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_4} alt="Service 4" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">CRM</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_4)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_5} alt="Service 5" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">Enhanced Security</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_5)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner rounded">
                <img className="img-fluid" src={service_6} alt="Service 6" />
                <div className="portfolio-text">
                  <h4 className="text-white mb-4">Seamless Integration</h4>
                  <div className="d-flex">
                    <a className="btn btn-lg-square rounded-circle mx-2" href={validateHref(service_6)} data-lightbox="portfolio"><i className="fa fa-eye" /></a>
                    <a className="btn btn-lg-square rounded-circle mx-2" href="#"><i className="fa fa-link" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Projects End */}
    </>
  );
}

export default Project;