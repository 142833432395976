import React, { useState, useEffect, Fragment } from 'react';
import logo from '../img/logo.png'
const Navbar = () => {
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);


    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const sections = ['home', 'about', 'services', 'projects', 'contact'];
    const observers = [];

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, { threshold: 0.6 });

    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        observer.observe(section);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(id);
    }
  };

  return (
    <Fragment>
      {loading && (
         <div id="spinner" className="show w-100 vh-100 bg-white position-fixed translate-middle top-50 start-50  d-flex align-items-center justify-content-center">
             <div className="spinner-grow text-primary" role="status"></div>
         </div>
      )}

      {!loading && (
        <Fragment>
         

          {/* Navbar Start */}
          <nav className={`navbar navbar-expand-lg navbar-light sticky-top p-0 ${scrolled ? 'bg-light shadow-sm' : 'bg-white'}`}>
            <div className="navbar-brand d-flex align-items-center px-4 px-lg-5">
	<a href="https://bizup.id/">  
            <img src={logo} alt="Community Logo" className="community-logo" />
        </a>
	<a href="https://bizup.id/">  
	      <h1 className="m-0">Bi-Search</h1>
         </a>   
	    </div>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto p-4 p-lg-0">
                <li className="nav-item">
                  <div className={`nav-link ${activeSection === 'home' ? 'active' : ''}`} onClick={() => scrollToSection('home')}>Home</div>
                </li>
                <li className="nav-item">
                  <div className={`nav-link ${activeSection === 'about' ? 'active' : ''}`} onClick={() => scrollToSection('about')}>About</div>
                </li>
                <li className="nav-item">
                  <div className={`nav-link ${activeSection === 'services' ? 'active' : ''}`} onClick={() => scrollToSection('services')}>Services</div>
                </li>
                <li className="nav-item">
                  <div className={`nav-link ${activeSection === 'projects' ? 'active' : ''}`} onClick={() => scrollToSection('projects')}>Projects</div>
                </li>
                <li className="nav-item">
                  <div className={`nav-link ${activeSection === 'contact' ? 'active' : ''}`} onClick={() => scrollToSection('contact')}>Contact</div>
                </li>
              </ul>
            </div>
          </nav>
          {/* Navbar End */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Navbar;
