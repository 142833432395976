import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { urlApi } from '../../features/authSlice';

const Navbar = () => {
  const [name, setName] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        // Step 1: Ambil data produk berdasarkan productId
        const productResponse = await axios.get(`${urlApi}/produk/${id}`);
        const tenantId = productResponse.data.tenantId;

        // Step 2: Ambil data tenant untuk mendapatkan komunitasId
        const tenantResponse = await axios.get(`${urlApi}/tenant/${tenantId}`);
        const komunitasId = tenantResponse.data.komunitas;

        // Step 3: Ambil data komunitas berdasarkan komunitasId
        const komunitasResponse = await axios.get(`${urlApi}/komunitas/${komunitasId}`);
        setName(komunitasResponse.data.nama);
        setLogoUrl(komunitasResponse.data.logoUrl);

      } catch (error) {
        console.error('Error fetching community data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunityData();
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Fragment>
      {loading && (
        <div id="spinner" className="show w-100 vh-100 bg-white position-fixed translate-middle top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-grow text-primary" role="status"></div>
        </div>
      )}

      {!loading && (
        <Fragment>
          {/* Navbar Start */}
          <nav className={`navbar navbar-expand-lg navbar-light sticky-top p-0 ${scrolled ? 'bg-light shadow-sm' : 'bg-white'}`}>
            <div className="navbar-brand d-flex align-items-center px-4 px-lg-5">
              {logoUrl ? <img src={logoUrl} alt="Community Logo" className="community-logo" /> : null}
              <h1 className="community-name">{name}</h1>
            </div>
          </nav>
          {/* Navbar End */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Navbar;
