import React, { useState } from 'react'
import Navbar from '../components/get/Navbar';
import Hero from '../components/get/Hero';
import Footer from '../components/get/Footer';
import Product from '../components/get/Product';

const GetComunitas = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  return (
    <div>
      <Navbar/>
      <Hero setSearchTerm={setSearchTerm} setSelectedLocations={setSelectedLocations} />
      <Product searchTerm={searchTerm} selectedLocations={selectedLocations} />
      <Footer/>
    </div>
  )
}

export default GetComunitas;
