import React from 'react'
import team1 from '../img/team-1.jpg'
import team2 from '../img/team-2.jpg'
import team3 from '../img/team-3.jpg'

const Team = () => {
  return (
    <div>
      {/* Team Start */}
  <div className="container-xxl py-5" id='contact'>
    <div className="container">
      <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <p className="fs-5 fw-bold text-primary">Our Team</p>
        <h1 className="display-5 mb-5">Dedicated &amp; Experienced Team Members</h1>
      </div>
      <div className="row g-4 d-flex justify-content-center">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item rounded">
            <img className="img-fluid" src={team1} alt="team1" />
            <div className="team-text">
              <h4 className="mb-0">Wahyu Ahmad Yassin</h4>
              <p className="text-primary">UI/UX Designer</p>
              <div className="team-social d-flex">
                <a className="btn btn-square rounded-circle me-2" href="https://www.facebook.com/yassin.ahmad.39948/"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-square rounded-circle me-2" href="https://www.linkedin.com/in/wahyuahmadyassin/"><i className="fab fa-linkedin" /></a>
                <a className="btn btn-square rounded-circle me-2" href="https://www.instagram.com/abg.wahyoe/"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item rounded">
            <img className="img-fluid" src={team2} alt="team2" />
            <div className="team-text">
              <h4 className="mb-0">Kukuh Ahyar Pattani</h4>
              <p className="text-primary">Backend Developer</p>
              <div className="team-social d-flex">
                <a className="btn btn-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-square rounded-circle me-2" href="https://www.linkedin.com/in/ahyar-pattani-24879728a/"><i className="fab fa-linkedin" /></a>
                <a className="btn btn-square rounded-circle me-2" href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item rounded">
            <img className="img-fluid" src={team3} alt="team3"/>
            <div className="team-text">
              <h4 className="mb-0">Muhammad Saefulloh</h4>
              <p className="text-primary">Frontend Developer</p>
              <div className="team-social d-flex">
                <a className="btn btn-square rounded-circle me-2" href="https://www.facebook.com/profile.php?id=100072403920336"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-square rounded-circle me-2" href="https://www.linkedin.com/in/muhammad-saefulloh/"><i className="fab fa-linkedin" /></a>
                <a className="btn btn-square rounded-circle me-2" href="https://www.instagram.com/m_saeful311/"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Team End */}
    </div>
  )
}

export default Team
