import React, { useEffect, Fragment, useState } from 'react';
import Header from '../components/Header';
import WOW from 'wowjs';
import 'animate.css/animate.min.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import About from '../components/About';
import Services from '../components/Services';
import Project from '../components/Project';
import Team from '../components/Team';
import Quote from '../components/Quote';
import Product from '../components/Product';

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const top = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (!window.MutationObserver) {
      console.warn('MutationObserver is not supported by your browser.');
      console.warn('WOW.js cannot detect DOM mutations, please call .sync() after loading new content.');

      // Add a simple MutationObserver polyfill
      window.MutationObserver = function(callback) {
        return {
          observe: function() {
            console.warn('Fallback MutationObserver: observe');
          },
          disconnect: function() {
            console.warn('Fallback MutationObserver: disconnect');
          }
        };
      };
    }

    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <Fragment>
      <div>
        <Navbar/>
        <Header setSearchTerm={setSearchTerm} setSelectedLocations={setSelectedLocations} />
        <Product searchTerm={searchTerm} selectedLocations={selectedLocations} />
        <About />
        <Services />
        <Project />
        <Team />
        <Footer />
        {/* Back to Top */}
        <button onClick={top} className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top">
          <i className="bi bi-arrow-up" />
        </button>
      </div>
    </Fragment>
  );
};

export default Home;
