import React, { Fragment, useEffect, useState } from 'react'
import { urlApi } from '../../features/authSlice';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Select from 'react-select';

const Hero = ({ setSearchTerm, setSelectedLocations }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    
    getData();
    getLocations();
  }, []); // Array ketergantungan kosong memastikan ini dijalankan sekali saat mount
  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/kategoriproduk`);
      const categories = response.data.kategoriProduk || []
      setData(categories.slice(0, 4)); // untuk membatasi jangan lebih dari 4
    } catch (error) {
      setLoading(false);
      Swal.fire('Error', 'There was an error fetching data!', 'error');
    } finally {
      setLoading(false);
    }
  };
  const getLocations = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${urlApi}/tenant`);
      const locationData = response.data.tenantList.map(item => ({
        value: item.alamat,
        label: item.alamat
      }));
      setLocations(locationData);
    } catch (error) {
      setLoading(false);
      Swal.fire('Error', 'There was an error fetching data!', 'error');
    } finally {
      setLoading(false);
    }
  };
   const handleSearchChange = (e) => {
    setText(e.target.value);
    setSearchTerm(e.target.value); // Update the search term in the parent component
  };
  return (
    <Fragment>
    <section className="hero-section d-flex justify-content-center align-items-center">
        <div className="section-overlay" />
        <div className="container">
            <div className="row justify-content-center">
            
            <div className="col-lg-9 col-12">
                <form className="custom-form hero-form" action="#" method="get" role="form">
                <h3 className="text-white text-center mb-3">Search </h3>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><i className="bi-person custom-icon" /></span>
                        <input type="text" value={text} onChange={handleSearchChange} className="form-control" placeholder="Search ..." required />
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                    <div className="custom-input-group">
                          <i className="bi-geo-alt custom-icon custom-input-group-icon" />
                          <Select
                            isMulti
                            options={locations}
                            className="custom-select-container"
                            classNamePrefix="custom-select"
                            placeholder="Select locations"
                            onChange={setSelectedLocations}
                            styles={{
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                fontFamily: 'Poppins',
                                fontSize: 15,
                                color: '#adb5bd',
                              }),
                              option: (provided) => ({
                                ...provided,
                                fontSize: 13,
                                fontFamily: 'Poppins',
                                color: '#000000',
                              }),

                              clearIndicator:(baseStyles) => ({
                                ...baseStyles,
                                color: 'red',
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: 0,
                              }),
                            }}
                          />
                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-12">
                    <button type="submit" className="form-control">
                        Find a job
                    </button>
                    </div>
                    <div className="col-12">
                    <div className="d-flex flex-wrap align-items-center mt-4 mt-lg-0">
                        <span className="text-white mb-lg-0 mb-md-0 me-2">Popular keywords:</span>
                        <div>
                        {data.map((kategori, index) => (
                            <Link className="badge" key={index}>{kategori.kategori}</Link>
                        ))}
                        </div>
                    </div>
                    </div> */}
                </div>
                </form>
            </div>
            </div>
        </div>
    </section>
    </Fragment>
  )
}

export default Hero;
