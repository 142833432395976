import React from 'react'
import Navbar from '../components/detail/Navbar'
import Detail from '../components/detail/Detail'
import Footer from '../components/detail/Footer'

const GetProduct = () => {
  return (
    <div>
      <Navbar/>
      <Detail/>
      <Footer/>
    </div>
  )
}

export default GetProduct
