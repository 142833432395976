import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { urlApi } from '../../features/authSlice';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

const Footer = () => {
    const [name, setName] = useState('');
  const [lokasi, setLokasi] = useState('');
  const [logoUrl, setLogoUrl] = useState(null)
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
    useEffect(() => {
      
        fetchCommunityData();
        getCategories();
    }, []);
  
    const fetchCommunityData = async () => {
      try {
        // Step 1: Ambil data produk berdasarkan productId
        const productResponse = await axios.get(`${urlApi}/produk/${id}`);
        const tenantId = productResponse.data.tenantId;

        // Step 2: Ambil data tenant untuk mendapatkan komunitasId
        const tenantResponse = await axios.get(`${urlApi}/tenant/${tenantId}`);
        const komunitasId = tenantResponse.data.komunitas;

        // Step 3: Ambil data komunitas berdasarkan komunitasId
        const komunitasResponse = await axios.get(`${urlApi}/komunitas/${komunitasId}`);
        setName(komunitasResponse.data.nama);
        setLokasi(komunitasResponse.data.lokasi);
        setLogoUrl(komunitasResponse.data.logoUrl);

      } catch (error) {
        console.error('Error fetching community data:', error);
      } finally {
        setLoading(false);
      }
    };
    const getCategories = async () => {
      try {
          const response = await axios.get(`${urlApi}/kategoriproduk`);
          let categoriesData = response.data.kategoriProduk || [];
          categoriesData = categoriesData.slice(0, 5);
          setCategories(categoriesData);
      } catch (error) {
          Swal.fire('Error', 'There was an error fetching data!', 'error');
      }
  };
  return (
    <Fragment>
         {/* Footer Start */}
  <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-4 col-md-6">
          <h4 className="text-white mb-4">Our Office</h4>
          {logoUrl ? (
           <img src={logoUrl} alt="Community Logo" className="community-logo justify-content-center" />
            ) : null}
          <p className="mb-2 mt-3"><i className="fa fa-map-marker-alt me-3" />{lokasi}</p>
         
        </div>
       
        <div className="col-lg-4 col-md-6">
          <h4 className="text-white mb-4">Kategori Product</h4>
          {categories.map((category, index) => (
          <a className="btn btn-link" key={index}>{category.kategori}</a>
                                ))}
        </div>
        <div className="col-lg-4 col-md-6">
        {logoUrl ? (
           <img src={logoUrl} alt="Community Logo" className="community-logo justify-content-center" />
            ) : null}
          <p className="mt-3">Komunitas {name} adalah sebuah komunitas yang berlokasi di {lokasi}</p>
         
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Copyright Start */}
  <div className="container-fluid copyright py-4">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
          © <a className="border-bottom" href='https://saefull.my.id/' >Solid team 2024</a>, All Right Reserved.
        </div>
      </div>
    </div>
  </div>
  {/* Copyright End */}
    </Fragment>
  )
}

export default Footer
